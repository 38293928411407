import React, { useState, useLayoutEffect } from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Gallery from "../components/gallery/gallery.js";

import throttle from "lodash.throttle";

const IndexPage = () => {
  //KEY LISTENER
  const [keyState, setKeyState] = useState(false);

  const keyTimeout = () => {
    setKeyState(false);
  };

  const throttleKeyTimeout = throttle(keyTimeout, 300, {
    leading: false,
    trailing: true,
  });

  const keyEventListener = (e) => {
    if (keyState) return;
    setKeyState(e.keyCode);
    throttleKeyTimeout();
  };

  useLayoutEffect(() => {
    document.addEventListener("keydown", keyEventListener);

    return () => {
      document.removeEventListener("keydown", keyEventListener);
    };
  }, []);

  return (
    <Layout gallery={true}>
      <SEO title="Sydney SG" />
      <Gallery keyCode={keyState} portfolio={2} />
    </Layout>
  );
};

export default IndexPage;
